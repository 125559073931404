<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { States } from '@/interfaces/States';
import { loadStripe } from '@stripe/stripe-js';
import { useRoute } from 'vue-router';
import axios from 'axios';
import { useToast } from 'vuestic-ui';
import { io } from 'socket.io-client';

const { notify } = useToast();

interface buttonItems {
  pricing: string;
  name: string;
  metadata: {
    price: number;
    time: number;
  };
}

const chair = ref<number>();
const block = ref<string>();
const row = ref<number>();

const chairValue = ref<string>();
const blockValue = ref<string>();
const rowValue = ref<string>();

const chairError = ref<boolean>(false);
const blockError = ref<boolean>(false);
const rowError = ref<boolean>(false);
const errorKey = ref<number>(0);

const blockOptions = [
  'Block: A',
  'Block: B',
  'Block: C',
  'Block: D',
  'Block: E',
  'Block: F',
];
const seatOptions = ['Sitz: 1', 'Sitz: 2'];
const rowOptions = [
  'Reihe: 1',
  'Reihe: 2',
  'Reihe: 3',
  'Reihe: 4',
  'Reihe: 5',
  'Reihe: 6',
  'Reihe: 7',
  'Reihe: 8',
  'Reihe: 9',
  'Reihe: 10',
];

const timestamp = Date.now();
const actualDate = new Date(timestamp);

let formattedDate = actualDate.toLocaleString('de-DE', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  timeZone: 'UTC',
});

//* helpers for countdown
let countDown = 0;
let intervalId: any = 0;
//* variable to countdown time that chair is still active
const time = ref<number>(0);
//* variable if timer is running or not
const state = ref<States>(States.STOPPED);
const showSetup = ref<boolean>(false);
//* helper for countdown
const isCountDown = ref<boolean>(false);
// ? variable for resume / pause button

//* format time when timer is expired
const endDate = computed(() => {
  const currentTime = new Date();
  currentTime.setSeconds(currentTime.getSeconds() + time.value);
  return currentTime.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  });
});

function notifyUser(
  title: string,
  message: string,
  color: string,
  closeable = false
) {
  notify({
    title,
    message,
    color,
    closeable,
  });
}

//* show timer running in minutes and seconds
const formattedTime = computed(() => {
  const minutes = Math.floor(time.value / 60);
  const seconds = time.value % 60;
  return `${minutes}:${seconds.toString().padStart(2, '0')}`;
});

//* set timer time
async function countDownTimer() {
  if (countDown && !isCountDown.value) {
    isCountDown.value = true;
    if (countDown > 0) {
      intervalId = setInterval(async () => {
        time.value--;
        if (time.value === 0) {
          clearInterval(intervalId);
          isCountDown.value = false;
          state.value = States.STOPPED;
          //* stop chair function
          //! remove daliTest and change it back to dali!
          await fetch('/api/daliTest/allOff', {
            method: 'POST',
            body: JSON.stringify({
              id: router.params.seatId,
            }),
          });
        }
      }, 1000);
    }
  }
}

async function changeTime(newTime: number) {
  //* start chair
  try {
    //! remove daliTest and change it back to dali!
    await fetch('/api/daliTest/allOn', {
      method: 'POST',
      body: JSON.stringify({
        id: router.params.seatId,
      }),
    });
  } catch (e) {
    console.log(e);
  }
  time.value += newTime;
  state.value = States.RUNNING;
  countDown = time.value;
  if (!isCountDown.value) await countDownTimer();
}

//* stripe payment
async function checkout(timerTime: number, price: string, productName: string) {
  //* api call to get session id
  try {
    localStorage.setItem('timerTime', timerTime?.toString());
    if (!timerTime || !price)
      throw new Error(
        'Zeit oder Preis wurde nicht für diese Option hinterlegt!'
      );
    const seatValues = {
      seat: chair.value,
      block: block.value,
      row: row.value,
    };
    const response = await fetch('/api/payments/create-checkout-session', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        time: timerTime, //! add * 60 for minutes
        completeTime: time.value,
        pricing: price,
        mandant: router.params.mandant,
        seat: router.params.seatId,
        seatValues,
        productName,
      }),
    });

    const { sessionId } = await response.json();

    //* redirect to, checkout site
    const stripe = await loadStripe(
      <string>process.env.VUE_APP_STRIPE_PUBLICKEY?.toString()
    );
    await stripe?.redirectToCheckout({ sessionId });
  } catch (e) {
    notifyUser('Error', `${e}`, 'danger');
  }
}

//* stripe payment validation and timer handling
function calculateRemainingSeconds(
  startTimeString: string,
  endTimeString: string
): number {
  const startTime = new Date(startTimeString);
  const endTime = new Date(endTimeString);
  const now = new Date();

  if (now < startTime) {
    return -1;
  }
  if (now > endTime) {
    return 0;
  }
  const timeDifferenceMs = endTime.getTime() - now.getTime();
  return Math.ceil(timeDifferenceMs / 1000);
}

async function getPaymentData(paymentId: string) {
  try {
    if (paymentId) {
      try {
        const response = await fetch(
          `/api/payments/payment-completed/${paymentId}`
        );
        const data = await response.json();
        const remainingSeconds = calculateRemainingSeconds(
          data.startTime,
          data.endTime
        );

        block.value = data.seatValues.block.slice('Block: ');
        chair.value = data.seatValues.seat;
        row.value = data.seatValues.row;

        if (
          !data.isPaused &&
          data.isValidated &&
          remainingSeconds &&
          paymentId
        ) {
          await changeTime(remainingSeconds);
        } else if (data.isPaused && data.remainingTime) {
          state.value = States.PAUSED;
          time.value = data.remainingTime;
        } else {
          notifyUser(
            'Ein Fehler ist aufgetreten!',
            'Leider ist bei der Zahlung etwas Schiefgelaufen!',
            'danger'
          );
        }
      } catch (e) {
        notifyUser('Ein Fehler ist aufgetreten!', `${e}`, 'info');
      }
    }
  } catch (error) {
    notifyUser(
      'Ein Fehler ist aufgetreten!',
      `Fehlercode für die Administratoren: ${error}`,
      'danger'
    );
  }
}
const router = useRoute();

const socket = ref();
const buttonArray = ref<Array<buttonItems>>([]);
const isLoading = ref<boolean>(true);

onMounted(async () => {
  const paymentId = router?.params?.id;
  await getPaymentData(paymentId?.toLocaleString());

  if (!chair.value) chair.value = Number(router.params.seatId) || undefined;
  if (!block.value)
    block.value = router?.params?.blockId?.toString()?.toUpperCase();
  if (!row.value) row.value = Number(router.params.rowId) || undefined;

  if (!chair.value || !block.value || !row.value) {
    showSetup.value = true;
  }

  try {
    isLoading.value = true;
    const response = await axios.get('/api/payments/products');
    buttonArray.value = response.data;
    isLoading.value = false;
  } catch (e) {
    notifyUser('Error beim Bereitstellen der Optionen', `${e}`, 'danger');
  }

  socket.value = io(<string>process.env.VUE_APP_SERVER_URL);

  socket.value.on('products', (products: buttonItems[]) => {
    buttonArray.value = products;
  });
});

async function pauseTimer() {
  try {
    const response = await axios.post('/api/payments/pauseTimer', {
      id: String(router.params.id),
      remainingTime: Number(time.value),
      isPaused: Boolean(state.value !== States.PAUSED), //* true, wenn pausiert wird, false beim Fortsetzen
    });

    if (response.data.success) {
      if (state.value === States.PAUSED) {
        //* Timer fortsetzen
        state.value = States.RUNNING;
        countDown = time.value;
        await countDownTimer();
      } else {
        //* Timer pausieren
        clearInterval(intervalId);
        isCountDown.value = false;
        state.value = States.PAUSED;
      }
    } else {
      console.error(response.status);
    }
  } catch (error) {
    console.error('Fehler bei der Anfrage:', error);
  }
}

function extractValue(inputString: string) {
  const parts = inputString.split(' ');
  return parts.slice(1).join(' ');
}

async function setupBanner() {
  if (blockValue.value && chairValue.value && rowValue.value) {
    if (blockValue.value) {
      block.value = extractValue(blockValue.value);
      blockError.value = false;
    }

    if (chairValue.value) {
      chair.value = parseInt(extractValue(chairValue.value));
      chairError.value = false;
    }

    if (rowValue.value) {
      row.value = parseInt(extractValue(rowValue.value));
      rowError.value = false;
    }

    showSetup.value = false;
  } else {
    notifyUser('Fehler', 'Bitte alle Felder ausfüllen!', 'danger', true);

    if (!blockValue.value) blockError.value = true;
    if (!rowValue.value) rowError.value = true;
    if (!chairValue.value) chairError.value = true;
  }
}

const getTicketBackground = computed(() => {
  return {
    backgroundColor: state.value === 'running' ? '#e7590f' : '#007bff',
  };
});

const getBackground = computed(() => {
  return {
    backgroundColor: state.value !== 'stopped' ? '#e7590f' : '#007bff',
  };
});
</script>

<template>
  <div class="seat-heating-container">
    <VaCard class="card">
      <div class="header" style="justify-content: center">
        <div v-show="false" class="icon-container">
          <VaIcon name="va-arrow-left" class="back-icon" />
        </div>
        <h2 v-if="state !== States.RUNNING">Sitzheizung aktivieren</h2>
        <h2 v-else>
          Aktiv bis <span style="color: #e7590f">{{ endDate }}</span> Uhr
        </h2>
        <div v-show="false" class="icon-container">
          <VaIcon v-show="false" name="va-arrow-down" class="more-icon" />
        </div>
      </div>

      <br />

      <div class="stadium-image-container">
        <img
          class="stadium-image"
          src="@/assets/eisenach.jpg"
          alt="Stadion Bild"
        />
        <div class="match-info-banner">
          <h1 class="mb-1">Wartburg Stadt Eisenach : FC Eisenach</h1>
          <p>{{ formattedDate }} | 18:00 Uhr | Wartburgstadion</p>
        </div>
      </div>

      <div v-show="!showSetup" class="seat-info">
        <p>Platz:</p>
        <h3>Block {{ block }} / Reihe {{ row }} / Sitz {{ chair }}</h3>
      </div>

      <div v-show="!showSetup" class="status-ticket-container">
        <div class="ticket-top-line"></div>
        <div class="status-ticket" :style="getTicketBackground">
          <div class="status-row">
            <span>Status:</span>
            <span>Minuten verbleibend:</span>
          </div>
          <div class="status-row-large">
            <span class="status-value">{{
              state === 'running'
                ? 'Heizt'
                : state === 'paused'
                  ? 'Pausiert'
                  : 'Heizt nicht'
            }}</span>
            <span class="time-remaining">{{ formattedTime }}</span>
          </div>
        </div>
        <va-button
          v-show="state !== 'stopped'"
          :color="state === 'running' ? '#007bff' : '#e7590f'"
          class="control-button"
          @click="pauseTimer"
          >{{ state === 'running' ? 'Pausieren' : 'Aktivieren' }}</va-button
        >
      </div>

      <div class="heating-container">
        <div v-show="!showSetup" class="cold-banner" :style="getBackground">
          <h2>{{ state === 'stopped' ? 'Kalt?' : 'Mehr Zeit?' }}</h2>
        </div>
        <div class="activate-heating">
          <h1 v-if="showSetup" class="mb-2 header">
            Platzinformationen hier eintragen!
          </h1>
          <h1 v-else class="mb-2 header">
            Jetzt Sitzheizung
            {{ state === 'stopped' ? 'aktivieren' : 'verlängern' }}.
          </h1>
          <p v-show="!showSetup">
            {{
              state === 'stopped'
                ? 'Für die gewünschte Zeit die Heizung des Sitzplatzes hier aktivieren und so einen komfortablen warmen Platz erhalten'
                : 'Für die gewünschte Zeit die Heizung des Sitzplatzes hier verlängern und so einen komfortablen warmen Platz behalten.'
            }}
          </p>
        </div>

        <div class="heating-options">
          <div v-if="showSetup" class="setup-items">
            <va-select
              :key="errorKey"
              v-model="blockValue"
              :error="blockError"
              :error-messages="['Bitte Ausfüllen!']"
              :options="blockOptions"
              placeholder="Block"
              immediate-validation
            />
            <va-select
              :key="errorKey"
              v-model="rowValue"
              :error="rowError"
              :error-messages="['Bitte Ausfüllen!']"
              :options="rowOptions"
              placeholder="Reihe"
              immediate-validation
            />
            <va-select
              :key="errorKey"
              v-model="chairValue"
              :error="chairError"
              :error-messages="['Bitte Ausfüllen!']"
              required-mark
              required
              :options="seatOptions"
              placeholder="Sitz"
              immediate-validation
            />
            <va-button @click="setupBanner">OK</va-button>
          </div>
          <div
            v-else-if="isLoading"
            style="
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              gap: 10px;
            "
          >
            <VaSkeleton
              v-for="number in [1, 2, 3, 4]"
              :key="number"
              animation="wave"
              class="price-button"
              style="max-height: 52px; width: 144px"
            >
              <div class="price-content">
                <div class="separator-vertical"></div>
              </div>
            </VaSkeleton>
          </div>

          <div
            v-for="option in buttonArray"
            v-else
            :key="option.name"
            class="price-button"
            @click="checkout(option.metadata.time, option.pricing, option.name)"
          >
            <div class="price-content">
              <div class="time">
                <span v-if="state === 'stopped'" class="time-large">{{
                  option.metadata.time
                }}</span>
                <span v-else class="time-large"
                  >+{{ option.metadata.time }}</span
                >
                <span class="time-small">Minuten</span>
              </div>
              <div class="separator-vertical"></div>
              <div class="price">
                <span class="price-large">{{ option.metadata.price }}</span>
                <span class="price-small">Euro</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </VaCard>
  </div>
</template>

<style lang="scss" scoped>
.seat-heating-container {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.card {
  max-width: 400px;
  width: 100%;
  padding: 20px;
}

.header {
  display: flex;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
}

.icon-container {
  background-color: #f0f0f0;
  border-radius: 50%;
  padding: 8px;
  cursor: pointer;
}

.back-icon,
.more-icon {
  font-size: 24px;
}

.stadium-image-container {
  position: relative;
}

.stadium-image {
  width: 100%;
  height: 180px;
  border-radius: 10px;
}

.match-info-banner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 12px;
  border-radius: 12px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 85%;

  p {
    font-size: 14px;
  }
}

.seat-info {
  text-align: center;
  margin: 20px 0;
}

.seat-info h3 {
  font-size: 24px;
  font-weight: bold;
  margin: 0;
}

.seat-info p {
  font-size: 14px;
  color: #777;
}

.status-ticket {
  color: white;
  padding: 20px 15px 15px 15px;
  border-radius: 0 0 12px 12px;
  margin: 0;
  text-align: center;
  font-size: 14px;
  position: relative;
  width: 75%;
  overflow: hidden;
}

.status-ticket::before,
.status-ticket::after {
  content: '';
  position: absolute;
  width: 15px;
  height: 15px;
  background-color: #fff;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
}

.status-ticket::before {
  left: -7.5px;
}

.status-ticket::after {
  right: -7.5px;
}

.ticket-top-line {
  position: absolute;
  display: flex;
  align-self: center;
  top: 0;
  width: 100%;
  height: 2px;
  background-color: #007bff;
}

.status-row {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  margin-bottom: 5px;
}

.status-row-large {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 0.5rem;
}

.status-value,
.time-remaining {
  font-weight: bold;
}

.heating-container {
  background-color: #fff;
  padding: 20px;
  position: relative;
  margin: 50px 0;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  h1 {
    font-size: 18px;
  }
}

.cold-banner {
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%) rotate(-5deg);
  color: white;
  display: inline-block;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 16px;
}

.activate-heating {
  text-align: center;
  margin: 20px 0;
}

.heating-options {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
}

.price-button {
  flex: 1 1 calc(48% - 10px);
  max-width: calc(48% - 10px);
  background-color: #f5f5f5;
  color: black;
  border: 1px solid #ccc;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 12px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition:
    background-color 0.3s,
    box-shadow 0.3s,
    transform 0.1s;
}

.price-button:hover {
  background-color: #ff9800;
  color: white;
}

.price-button:active {
  transform: scale(0.98);
  background-color: #e7590f;
  color: white;
}

.price-content {
  display: flex;
  align-items: stretch;
  height: 100%;
}

.time,
.price {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.time-large,
.price-large {
  font-size: 18px;
  font-weight: bold;
}

.time-small,
.price-small {
  font-size: 12px;
}

.separator-vertical {
  width: 1px;
  height: 100%;
  background-color: #ccc;
  margin: 0 10px;
}

.price-button.selected .separator-vertical {
  background-color: white;
}

.status-ticket-container {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
}

.control-button {
  position: absolute;
  top: 75%;
  border-radius: 1rem;
}

.setup-items {
  width: 100%;
  gap: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center !important;
}

@media (max-width: 768px) {
  .seat-heating-container {
    padding: 10px;
  }

  .card {
    max-width: 100%;
    padding: 15px;
  }

  .stadium-image {
    height: 150px;
  }

  .match-info-banner {
    width: 95%;
    padding: 10px;
  }

  .price-button {
    flex: 1 1 100%;
    max-width: 100%;
    margin-bottom: 10px;
  }

  .control-button {
    top: 85%;
  }

  .activate-heating {
    width: 100%;

    h1 {
      font-size: 0.9rem;
    }

    p {
      font-size: 0.75rem;
    }
  }

  .seat-info {
    h3 {
      font-size: 1.2rem;
    }
  }

  .heating-container {
    margin-top: 50px;
  }

  .cold-banner {
    top: -15px;
  }
}
</style>
