import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import HomeView from '@/views/HomeView.vue';
import FailedPayment from '@/views/FailedPayment.vue';
import SelectContextView from '@/views/SelectContextView.vue';
import HomeTempView from '@/views/HomeTempView.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    redirect: 'mandant',
    component: HomeTempView,
    children: [
      {
        path: ':mandant',
        name: 'mandant',
        component: HomeTempView,
        children: [
          {
            path: ':seatId/:blockId?/:rowId?',
            name: 'seatId',
            component: HomeTempView,
          },
        ],
      },
    ],
  },
  {
    path: '/completed/:id',
    name: 'id',
    component: HomeTempView,
  },
  {
    path: '/cancel',
    name: 'cancel',
    component: FailedPayment,
  },
  {
    path: '/select',
    name: 'select',
    component: SelectContextView,
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const validMandants = ['demo', 'completed'];
  const mandant = to.params.mandant;

  if (mandant && validMandants.includes(<string>mandant)) {
    next();
  } else if (!mandant) {
    next();
  } else {
    next({ path: '/demo' });
  }
});

export default router;
